import { FC, useState } from "react";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { Button } from "components/Button";
import { DateInfoTitle } from "components/DateInfoTitle";
import DatePicker from "components/DatePicker";
import { DropDownFilter } from "components/DropDownFilter";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { Box } from "ui/Box";

import { clearDate } from "helpers";

import { SxProps } from "@mui/material";
import { basicTheme } from "theme";
import { styles } from "./styles";

import { useBreakpoints } from "hooks/useBreakpoints";

export interface DateFilterProps {
  selectedDateRange: { startDate: Date; endDate: Date } | {};
  setSelectedDateRange: (
    value: React.SetStateAction<{ startDate: Date; endDate: Date } | {}>
  ) => void;
  minDate?: Date;
  maxDate?: Date;
  disabledDates?: Date[];
  disabled?: boolean;
}

export const DateFilter: FC<DateFilterProps> = ({
  selectedDateRange,
  setSelectedDateRange,
  minDate,
  maxDate,
  disabledDates,
  disabled = false,
}) => {
  const [openFilter, setOpenFilter] = useState(false);
  const [filterSelection, setFilterSelection] = useState<
    { startDate: Date; endDate: Date } | {}
  >({});

  const { isUpTablet } = useBreakpoints();

  const title = () => {
    if (
      selectedDateRange &&
      "startDate" in selectedDateRange &&
      "endDate" in selectedDateRange
    ) {
      if (!isUpTablet) {
        return <CalendarIcon gradient={true} />;
      }
      return `${clearDate(selectedDateRange.startDate)} - ${clearDate(
        selectedDateRange.endDate
      )}`;
    }
    return isUpTablet ? "All periods" : <CalendarIcon />;
  };
  const handleCalendarChange = (selection: {
    startDate: Date;
    endDate: Date;
  }) => {
    setFilterSelection(selection);
  };

  const closeFilterHandler = () => {
    setOpenFilter(false);
    setFilterSelection(selectedDateRange);
  };

  return (
    <ClickAwayListener onClickAway={closeFilterHandler}>
      <Box
        sx={
          disabled
            ? ({ ...styles.root, ...styles.rootDisabled } as SxProps)
            : styles.root
        }
      >
        <DropDownFilter
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          title={title()}
          disabled={disabled}
        />
        {openFilter && (
          <Box sx={styles.dropDown}>
            <Box sx={styles.datePicker}>
              <Box sx={styles.dateInfoTitle}>
                <DateInfoTitle selectedDateRange={filterSelection} />
              </Box>
              <DatePicker
                onChange={handleCalendarChange}
                months={1}
                
                startDate={
                  (selectedDateRange as { startDate: Date; endDate: Date })
                    .startDate
                }
                endDate={
                  (selectedDateRange as { startDate: Date; endDate: Date })
                    .endDate
                }
                minDate={minDate}
                maxDate={maxDate}
                disabledDates={disabledDates}
              />
              <Box sx={styles.buttonBox}>
                <Button
                  buttonTitle="Reset"
                  variantType="secondary"
                  secondaryTextColor={
                    basicTheme.palette.mainPalette.primaryBlack
                  }
                  onClick={() => {
                    setSelectedDateRange({});
                    sessionStorage.setItem("allPeriods", "true");
                    setFilterSelection({});
                    setOpenFilter(false);
                  }}
                  sx={{ mr: "auto" }}
                />
                {/* TODO: make separate component with cancel and apply buttons */}
                <Button
                  buttonTitle="Cancel"
                  variantType="secondary"
                  secondaryTextColor={
                    basicTheme.palette.mainPalette.primaryBlack
                  }
                  onClick={closeFilterHandler}
                />
                <Button
                  buttonTitle="Apply"
                  primaryBgColor={basicTheme.palette.gradientsPalette.gradient5}
                  onClick={() => {
                    setSelectedDateRange(filterSelection);
                    setOpenFilter(false);
                    sessionStorage.setItem("allPeriods", "false");
                  }}
                />
              </Box>
            </Box>
          </Box>
        )}
      </Box>
    </ClickAwayListener>
  );
};
