import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface UserInfo {
  isTestCompany: boolean;
  setTestCompany: (isTestCompany: boolean) => void;
}

export const useUserInfo = create<UserInfo>()(
  persist(
    (set) => ({
      isTestCompany: false,
      setTestCompany: (isTestCompany: boolean) => set({ isTestCompany }),
    }),
    {
      name: "user-info-storage",
      partialize: (state) => ({ isTestCompany: state.isTestCompany }),
    }
  )
);